<div
  class="hotkeys-container table! fixed w-full h-full top-0 left-0 fade bg-slate-800/80 text-pink-100 text-base md:text-lg lg:text-xl"
  [ngClass]="{ 'in': helpVisible$ | async }"
  style="display:none">
  <div class="w-full h-full align-middle table-cell">
    <h4 class="text-2xl md:text-3xl my-4 text-pink-300 text-center">{{ title }}</h4>
    <table class="m-auto">
      <tbody>
        <tr *ngFor="let hotkey of hotkeys">
          <td class="p-1 text-right">
            <span
              *ngFor="let key of hotkey.formatted"
              class="hotkeys-key text-md md:text-lg lg:text-xl inline-block text-pink-50 bg-slate-950 border-[1px] border-slate-950 rounded-md text-center mr-1 px-3 py-1 font-mono">
              {{ key }}
            </span>
          </td>
          <td class="pl-4 text-md md:text-lg">{{ hotkey.description }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="hotkeys-close cursor-pointer px-4 py-1 border-2 text-pink-600 border-pink-600/80 hover:bg-pink-600/30 text-5xl top-10 right-10 fixed rounded-full text-center transition-colors"
      (click)="toggleCheatSheet()">
      &#215;
    </div>
  </div>
</div>
