import { Component } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cs-faqs',
  imports: [CommonModule, ContainerComponent],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FaqsComponent {
  faqs = [
    [
      {
        question: 'How is this site different?',
        answer: 'A lot of other dating sites are plagued by fake accounts. We aim to keep this community free of these problems with our profile verification, technology and community moderation features. With your support we strive to make this an engaging, exciting and safe community.',
      },
      {
        question: 'Is the payment transaction discrete?',
        answer: 'Yes, as the service was built by a local software company, the payments will be made to TYSOFT.',
      },
      {
        question: 'How do I apply for a job?',
        answer: "We will be considering applications for community support in the near future, so feel free to express your interest with a message to: <a href=\"mailto:careers@casualse.xyz\">careers@casualse.xyz</a>",
      },
    ],
    [
      {
        question: 'Do you support Purchasing Power Parity?',
        answer: 'Yes! We have designed our pricing per country to recognise differences in purchasing power. At the time of purchase, when you select the country you reside in, you will be offered a price that is had been adjusted according to the relative purchasing power of your country.',
      },
      {
        question: 'TaxPal sounds horrible but why do I still feel compelled to purchase?',
        answer:
          "This is the power of excellent visual design. You just can't resist it, no matter how poorly it actually functions.",
      },
      {
        question: 'I found other companies called TaxPal, are you sure you can use this name?',
        answer:
          "Honestly not sure at all. We haven't actually incorporated or anything, we just thought it sounded cool and made this website.",
      },
    ],
    [
      {
        question: 'How do you generate reports?',
        answer:
          'You just tell us what data you need a report for, and we get our kids to create beautiful charts for you using only the finest crayons.',
      },
      {
        question: 'Can we expect more inventory features?',
        answer: "In life it's really better to never expect anything at all.",
      },
      {
        question: 'I lost my password, how do I get into my account?',
        answer:
          'Send us an email and we will send you a copy of our latest password spreadsheet so you can find your information.',
      },
    ],
  ];
}
